var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('dialog-component',{attrs:{"title":'Entities Fields',"visible":_vm.relationshiopFieldsDialogVisible},on:{"close":_vm.closeDialog}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"popUp-content"},[_c('h4',[_vm._v("New Relationships")]),_c('el-row',[_c('el-col',{attrs:{"span":10}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.newRelationshipsData}},[_c('el-table-column',{attrs:{"prop":"parent_entity_id","label":"Entity","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-select',{attrs:{"disabled":""},model:{value:(
                    _vm.newRelationshipsData[scope.$index].parent_entity_id
                  ),callback:function ($$v) {_vm.$set(_vm.newRelationshipsData[scope.$index], "parent_entity_id", $$v)},expression:"\n                    newRelationshipsData[scope.$index].parent_entity_id\n                  "}},_vm._l((_vm.allEntities),function(entity,index){return _c('el-option',{key:index,attrs:{"label":entity.name,"value":entity._id}})}),1)]}}])}),_c('el-table-column',{attrs:{"prop":"child_relation_type","label":"Has","width":"80"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.newRelationshipsData[scope.$index].child_relation_type)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"child_entity_id","label":"Child Entity","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-select',{attrs:{"disabled":""},model:{value:(_vm.newRelationshipsData[scope.$index].child_entity_id),callback:function ($$v) {_vm.$set(_vm.newRelationshipsData[scope.$index], "child_entity_id", $$v)},expression:"newRelationshipsData[scope.$index].child_entity_id"}},_vm._l((_vm.allEntities),function(entity,index){return _c('el-option',{key:index,attrs:{"label":entity.name,"value":entity._id}})}),1)]}}])}),_c('el-table-column',{attrs:{"prop":"child_relation_title","label":"As","width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{model:{value:(
                    _vm.newRelationshipsData[scope.$index].child_relation_title
                  ),callback:function ($$v) {_vm.$set(_vm.newRelationshipsData[scope.$index], "child_relation_title", $$v)},expression:"\n                    newRelationshipsData[scope.$index].child_relation_title\n                  "}})]}}])})],1)],1),_c('el-col',{attrs:{"span":14}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.newRelationshipsData}},[_c('el-table-column',{attrs:{"prop":"parent_entity_id","label":"Entity","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-select',{attrs:{"disabled":""},model:{value:(_vm.newRelationshipsData[scope.$index].child_entity_id),callback:function ($$v) {_vm.$set(_vm.newRelationshipsData[scope.$index], "child_entity_id", $$v)},expression:"newRelationshipsData[scope.$index].child_entity_id"}},_vm._l((_vm.allEntities),function(entity,index){return _c('el-option',{key:index,attrs:{"label":entity.name,"value":entity._id}})}),1)]}}])}),_c('el-table-column',{attrs:{"prop":"child_relation_type","label":"Has","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-select',{model:{value:(
                    _vm.newRelationshipsData[scope.$index].parent_relation_type
                  ),callback:function ($$v) {_vm.$set(_vm.newRelationshipsData[scope.$index], "parent_relation_type", $$v)},expression:"\n                    newRelationshipsData[scope.$index].parent_relation_type\n                  "}},[_c('el-option',{attrs:{"label":"MANY","value":"MANY"}}),_c('el-option',{attrs:{"label":"ONE","value":"ONE"}})],1)]}}])}),_c('el-table-column',{attrs:{"prop":"child_entity_id","label":"Child Entity","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-select',{attrs:{"disabled":""},model:{value:(
                    _vm.newRelationshipsData[scope.$index].parent_entity_id
                  ),callback:function ($$v) {_vm.$set(_vm.newRelationshipsData[scope.$index], "parent_entity_id", $$v)},expression:"\n                    newRelationshipsData[scope.$index].parent_entity_id\n                  "}},_vm._l((_vm.allEntities),function(entity,index){return _c('el-option',{key:index,attrs:{"label":entity.name,"value":entity._id}})}),1)]}}])}),_c('el-table-column',{attrs:{"prop":"parent_relation_title","label":"As","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-input',{model:{value:(
                    _vm.newRelationshipsData[scope.$index].parent_relation_title
                  ),callback:function ($$v) {_vm.$set(_vm.newRelationshipsData[scope.$index], "parent_relation_title", $$v)},expression:"\n                    newRelationshipsData[scope.$index].parent_relation_title\n                  "}})]}}])}),_c('el-table-column',{attrs:{"prop":"parent_relation_title","label":"Actions","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"icon":"el-icon-check","type":"success","size":"mini"},on:{"click":function($event){return _vm.addRelationship(scope.$index)}}})]}}])})],1)],1)],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.relationshiopFieldsDialogVisible = false}}},[_vm._v("Close ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }